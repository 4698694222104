
.social-grid {  
    display: grid;  
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr)); /* 4 colonnes qui s'adaptent à l'espace disponible */  
    gap: 60px; /* Espace entre les images */  
    justify-items: center; /* Centrer les images horizontalement */  
    align-items: center; /* Centrer les images verticalement */  
    max-width: 800px; /* Largeur maximale */  
    margin: 0 auto; /* Centrage horizontal */  
    padding: 20px; /* Espacement intérieur */  
}  

.social-grid img {  
    width: 100%; /* Largeur 100% pour s'adapter à la cellule */  
    height: auto; /* Hauteur automatique pour conserver le ratio d'aspect */  
    max-width: 80px; /* Largeur maximale pour éviter des images trop grandes */  
    max-height: 80px; /* Hauteur maximale pour éviter des images trop grandes */  
    object-fit: contain; /* Pour garder le ratio d'aspect */  
}

@media (max-width: 768px) {  
  .social-grid {  
    display: grid; 
    grid-template-rows: 4fr; /* Afficher une image à la fois sur mobile */
    gap: 10px;  
  }  
}  