

@media only screen and (max-width:768px) {
    .container .emtop {
        margin-top: -40px;
        margin-bottom: -40px;
    }
}  

@media only screen and (max-width:576px) {
    .container .emtop {
        margin-top: -20px;
        margin-bottom: -20px;
    }
}