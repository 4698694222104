.card {
    width: 250px;
    height: 330px;
   border: none;
   margin-bottom: -30px;
}

.title {
    margin-top: 20px;
}

.button-slider {
    margin-bottom: -10px;
    color: #fff;
    background: crimson;
    width: 200px;
    height: 30px;
    margin-top: 10px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
}

.box {
    margin-top: 70px;
}
.lave-box {
    margin-top: 0px;
}


@media only screen and (max-width:992px) {
    .card {
        width: 200px;
        height: 300px;
       border: none;
       margin-bottom: -30px;
    }

    .button-slider {
        margin-bottom: -10px;
        color: #fff;
        background: crimson;
        width: 150px;
        height: 30px;
        margin-top: 10px;
        border: none;
        border-radius: 20px;
        cursor: pointer;
    }
}

@media only screen and (max-width:768px) {
    .card {
        width: 150px;
        height: 330px;
       border: none;
       margin-bottom: -30px;
    }

    .button-slider {
        margin-bottom: -10px;
        color: #fff;
        background: crimson;
        width: 200px;
        height: 30px;
        margin-top: 10px;
        border: none;
        border-radius: 20px;
    }
}  

@media only screen and (max-width:576px) {
    .card {
        width: 200px;
        height: 330px;
       border: none;
       margin-bottom: -30px;
    }

    .button-slider {
        margin-bottom: -10px;
        color: #fff;
        background: crimson;
        width: 150px;
        height: 30px;
        margin-top: 10px;
        border: none;
        border-radius: 20px;
    }
}
