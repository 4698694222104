


.best {
    width: 100%;
    margin-top: -40px;
    text-align: center;
}
.btn-best {
    margin-top: 10px;
    margin-bottom: 20px;
    width: 200px;
    height: 30px;
    border: none;
    border-radius: 20px;
    color: white;
    background: crimson;
    cursor: pointer;
    box-shadow: #fff;
    font-style: bold;
}
.best > div {
    display: flex;
    justify-content: center;
}

.best > div p {
    max-resolution: 1rem 2rem;
    font-size: 1.2rem;
}
.bold {
    font-weight: 700;
}

.best .container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.2rem;
    padding: 1rem;
}

.best img {
    width: 100%;
    border-radius: 20px;
}

@media screen and (max-width:940px) {
    .best {
        margin: 1rem 0;
    }
    .best > div p {
        margin: 1rem;
        font-size: 1rem;
    }
    .best .container {
        grid-template-columns: 1fr;
        grid-gap: .5rem;
    }
}

@media screen and (max-width: 470px) {
    .best > div p {
        margin: 1rem .5rem;
    }
   
    #hisense-title {
        margin-top: -20px;
    }
}