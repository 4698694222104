

.table tr td img {
    width: 80px !important;
    height: 80px !important;
    object-fit: cover;
}

td i {
    cursor: pointer;
}