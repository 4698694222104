

.common__section {
    background:  url('../assets/images/g16.jpg') 
    no-repeat center center;
    background-size: cover;
    height: 300px;
    /*padding: 100px 0px;*/
    display: flex;
    align-items: center;
    justify-content: center;

}

.common__section h1{
    color: #fff;
    font-weight: 600;
}

@madia only screen and (max-width:768px) {
   .common__section {
    padding: 70px 0px;
   } 

   .common__section h1 {
    font-size: 1.4rem
   }
}