
.product__item {
    cursor: pointer;
}

.product__info h3 a {
    font-size: 1.2rem;
    color: var(--primary-color);
    font-weight: 600;
    margin-top: 15px;
}

h3:hover {
    color: crimson;
}

.product__info a:hover {
    color: inherit;
}

.product__info span {
    font-size: 0.9rem;
}

.product__card-bottom .price {
    color: crimson;
    font-size: 1.3rem;
    font-weight: 500;
}

.product__card-bottom span i {
    font-size: 1.2rem;
    padding: 5px;
    background: crimson;
    color: #fff;
    border-radius: 50px;
}

.priceNew {  
  text-decoration: line-through;  
  margin-right: 10px;  
  color: #888; /* Couleur grise pour le prix ancien */  
  font-weight: bold;
  font-size: 1.3rem;
  font-weight: 500;
} 

.message {
    color: crimson;
    font-weight: 600;
}

@media only screen and (max-width:992px) {
    .product__info h3 a {
        font-size: 1.1rem;
    }
}

@media only screen and (max-width:992px) {
    .product__info h3 a {
        font-size: 1rem;
    }

    .product__card-bottom span i {
        font-size: 1rem;
    }
}