* {  
  box-sizing: border-box;  
}  

 
.gallery-grid {  
  display: grid;  
  grid-template-columns: repeat(2, 1fr);  
  gap: 10px; /* Espace entre les images */  
  padding: 20px;  
}  

.grid-item {  
  overflow: hidden; /* Masque les débordements pour le zoom */  
}  

.thumbnail {  
  width: 100%;  
  height: auto;  
  transition: transform 0.3s ease; /* Transition douce pour le zoom */  
}  

.thumbnail:hover {  
  transform: scale(1.05); /* Légère augmentation lors du survol */  
}  

.full-image-modal {  
  position: fixed;  
  top: 0;  
  left: 0;  
  right: 0;  
  bottom: 0;  
  background-color: rgba(0, 0, 0, 0.9);  
  display: flex;  
  justify-content: center;  
  align-items: center;  
  z-index: 1000;  
  flex-direction: column;  
  cursor: pointer; /* Indication que la zone est cliquable */  
}  

.full-image {  
  max-width: auto;  
  max-height: auto; /* Limite la hauteur pour éviter le débordement sur l'écran */  
  transition: transform 0.3s ease;  
}  

.full-image:hover {  
  transform: scale(1.1); /* Zoom à 110% au survol */  
}  

/* Style pour les boutons de navigation */  
.nav-button {  
  background-color: white;  
  border: none;  
  padding: 10px 20px;  
  margin: 10px;  
  cursor: pointer;  
  border-radius: 5px;  
  font-size: 16px;  
  position: absolute; /* Pour le positionnement absolu */  
  top: 50%; /* Centré verticalement */  
  transform: translateY(-50%); /* Ajuster le centrage */  
}  

.prev {  
  left: 20px; /* Positionnement à gauche */  
}  

.next {  
  right: 20px; /* Positionnement à droite */  
}  

.close-button {  
  background-color: white;  
  border: none;  
  padding: 10px 20px;  
  cursor: pointer;  
  border-radius: 5px;  
  font-size: 16px;  
  position: absolute; /* Positionnement absolu pour le bouton de fermeture */  
  top: 20px; /* Position au-dessus de l'image */  
  right: 20px; /* Aligné à droite */  
}  

.nav-button:hover, .close-button:hover {  
  background-color: lightgray; /* Changement de couleur au survol */  
}  

/* Media Queries pour rendre le carrousel responsive */  
@media (max-width: 768px) {  
  .gallery-grid {  
    grid-template-columns: 1fr; /* Une seule colonne pour les petits écrans */  
  }  

  .full-image {  
    width: 100%; /* L'image occupe toute la largeur sur mobile */  
    height: auto; /* Hauteur automatique pour garder le ratio */  
  }  

  .nav-button {  
    padding: 10px 15px; /* Ajuster le padding pour mobile */  
    font-size: 14px; /* Réduire la taille de la police sur mobile */  
  }  

  .close-button {  
    padding: 10px 15px; /* Ajuster le padding pour mobile */  
    font-size: 14px; /* Réduire la taille de la police sur mobile */  
  }  
}