 

.grid-container_grid {  
  display: grid;  
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr)); /* Responsive */  
  gap: 20px; 
  padding: 20px;  
  margin: 10px; /* Ajoute de la marge autour de la grille */  
  max-width: auto; /* Largeur maximale de la grille */  
  width: 100%; /* S'assurer que ça prend la largeur disponible */  
}  

.hover-text {  
    font-size: 20px; /* Taille de la police */  
    color: black;    /* Couleur du texte */  
    transition: text-decoration 0.3s ease; /* Ajouter une transition douce */  
}  

.hover-text:hover {  
    text-decoration: underline; /* Souligner au survol */  
}

.grid-item_grid {  
  background-color: #fff;  
  border: none;  
  border-radius: 5px;  
  padding: 10px;  
  text-align: center;  
  transition: transform 0.3s, box-shadow 0.3s; /* Transition pour l'effet de zoom */  
}  

.grid-item_grid:hover {  
  transform: scale(1.08); /* Zoom lorsque l'on survole l'élément */  
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.0); /* Ombre au survol */  
}  

.product-image_grid {  
  width: 100%; /* Largeur d'image à 100% */  
  height: auto; /* Conserve le ratio d'aspect */  
  border-radius: 5px; /* Coins arrondis sur l'image */  
  margin-bottom: 30px;
}  

.buy-button {  

  cursor: pointer;  
    color: #fff;
    background: crimson;
    width: 200px;
    height: 30px;
    border: none;
    border-radius: 20px;
  
  }
@media (max-width: 768px) {  
  .grid-container_grid {  
    grid-template-columns: 1fr; /* Afficher une image à la fois sur mobile */  
  }   
}