/* google fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');
/*  css variables */
:root {
  --card-bg-01: #fdefe6;
  --card-bg-02: #d6e5fb;
  --card-bg-03: #ceebe9;
  --card-bg-04: #e2f2b2;
  --primary-color: #484747;
  --hero-bg: #d6e5fb;
  --heading-fontSize: 2rem;
  --small-text-color: #999;
  --heading-text-color: #0a1d37;
}

/* reset browser default */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0;
  margin: 0;
}

p {
  color: var(--small-text-color);
  font-size: 1rem;
}

h1,
h2 {
  font-size: var(--heading-fontSize);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: unset;
}


img {
  width: 100%;
}

section {
  padding: 60px 0px;
}

.section__title {
  color: var(--primary-color);
  font-weight: 600;
}

.spinners {
  margin-left: 500px;
  margin-right: 500px;
  margin-top: 300px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width:768px) {
    section {
      padding: 40px 0px;
    }

    .spinners {
      margin-left: 125px;
      margin-right: 125px;
      margin-top: 250px;
      text-align: center;
      align-items: center;
      
    }
}

@media only screen and (max-width:412px) {
 
  .spinners {
    margin-left: 80px;
    margin-right: 80px;
    margin-top: 200px;
    text-align: center;
    align-items: center;
    
  }
}