

.services__item {
    padding: 20px;
 
    display: flex;
    align-items: center;
    column-gap: 0.7rem;
    border-radius: 5px;
    cursor: pointer;
}

.services__item span i {
    font-size: 2.2rem;
    background: var(--primary-color);
    padding: 10px;
    border-radius: 50px;
    color: #fff;
    font-weight: 400 !important;
}

.services__item h3 {
    color: var(--primary-color);
    font-size: 1.1rem;
    font-weight: 600;
}

.services__item p {
    font-size: 0.9rem;
    margin-top: 5px;
    color: #222;
}

@media only screen and (max-width:992px) {
    .services__item span i {
        font-size: 1.8rem;
        padding: 5px;
    }

    .services__item h3 {
        font-size: 1rem;
    }

    .services__item {
        padding: 10px;
        margin-bottom: 1.5rem;
    }
    .services .services {
        margin-top: -40px;
        margin-bottom: -40px;
    }
}

@media only screen and (max-width:768px) {
  
    .services__item span i {
        font-size: 1.5rem;
        padding: 5px;
    }
    .services .services {
        margin-top: -4
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        


































































































































































































































































































        
        
        
        
        
        
        
        0px;
        margin-bottom: -40px;
    }
}

@media only screen and (max-width:576px) {
    .services__item {
        padding: 20px;
    }

    .services .services {
        margin-top: -20px;
        margin-bottom: -20px;
    }
}
