.trending__products {
    margin-top: -100px;
}

.trending__products .hisense {
    margin-top: -300px;
}

.featured {
    margin-bottom: -200px;
}
.hero__section {
    background: var(--hero-bg);
}

.text-center {
    box-shadow: #dc3545;
}

.hero__content h2 {
    color: var(--primary-color);
    font-size: 2.5rem;
    font-weight: 600;
    margin: 20px 0px;
}

.hero__content p {
    color: var(--primary-color);
    line-height: 28px;
}

.img-color {
    border-radius: 20px;
    margin-top: 2rem;
}

.buy__btn {
    border: none;
    outline: none;
    padding: 8px 20px;
    border-radius: 5px;
    background: crimson;
    color: #fff;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 40px;
}

.buy__btn a:hover {
    color: crimson;
}

.hero__content {
    padding-top: 45px;
}

.timer__count {
    background: var(--primary-color);
    height: 300px;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.timer__count img {
    width: 70%;
    height: 70% !important;
    object-fit: contain;
}

.store__btn {
    background: crimson;
    color: white;
    font-weight: 600;
}

.store__btn a:hover {
    color: var(--primary-color);
}

.back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 996;
    background: #dc3545;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    transition: all 0.4s;
  }
  
  .back-to-top:hover {
    background: #dc3545;
    color: #fff;
  }
  .carousel {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
@media only screen and (max-width:992px) {
    .hero__content h2 {
        font-size: 2rem;
    }

    .section__title .titre {
        font-size: 1.5rem;
    }

    .counter__img {
        display: none;
    }

    .clock__top-content {
        text-align: center;
    }

    .clock__top-content h4 {
        font-size: 0.9rem !important;
    }

    .clock__top-content h3 {
        font-size: 1rem !important;
    }

    .count__down-col {
        text-align: center;
        
    }

    .trending__products {
        margin-top: -50px;
    }

    .featured {
        margin-bottom: -150px;
    }

    
}

@media only screen and (max-width:768px) {
    .hero__content h2 {
        font-size: 1.6rem;
    }

    .hero__content p {
        font-size: 0.9rem;
    }

    .buy__btn a {
        font-size: 0.9rem;
    }

    .section__title {
        font-size: 1.4rem;
    }

    .trending__products {
        margin-top: -40px;
    }

    .featured {
        margin-bottom: -100px;
    }
    .section__title .titre {
        font-size: 1.5rem;
    }

    .imageHisense {
        margin-top: 20px;
        margin-bottom: -50px;
    }
}  

@media only screen and (max-width:576px) {
    .hero__content h2 {
        padding: 0;
        margin-bottom: 40px;
    }

    .trending__products {
        margin-top: -30px;
    }

    .featured {
        margin-bottom: -100px;
    }

    .timer__count {
       margin-top: 60px;
    }
    .section__title .titre {
        font-size: 1.5rem;
    }
    .text-center {
        margin-top: 10px;
    }

    .section__title .titre {
        font-size: 1.5rem;
    }

    .imageHisense {
        margin-top: 20px;
        margin-bottom: -50px;
    }
}



